<script>
  export let selectPrice = () => {}
  export let id = ""
  export let basePrice
  export let price = 0
  export let staff = ""
  export let period = "month"
  export let loading = false

  function formatPrice(number) {
    return `£${(number / 100).toFixed()}`
  }
</script>

<button
  disabled={loading}
  class={`container ${loading && "container--loading"}`}
  on:click={() => {
    selectPrice(id)
  }}
>
  <section class="body">
    <h2 class="title">
      {staff}
    </h2>
    <p class="price">
      {#if basePrice}
        <span class="basePrice">{formatPrice(basePrice)}</span>
      {/if}

      {formatPrice(price)} / {period === "year" ? "year" : "month"}
    </p>
  </section>
  <button disabled={loading} class="button">
    {loading ? "Loading..." : "Subscribe"}
  </button>
</button>

<style>
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    text-align: left;
    /* min-width: 150px; */
    /* //calc(50% - 0.7rem); */
    /* width: 100%; */
    padding: 1rem;
    border-radius: 0.5rem;
    border: none;
    border: 1px solid #ccc;
    background: #fff;
    /* box-shadow: 10px 10px 10px #d9d9d9, -10px -10px 30px #ffffff; */
    cursor: pointer;
  }

  .container--loading {
    opacity: 0.3;
  }

  .container:focus {
    background-color: #fff;
  }

  .body {
    flex: 1;
  }
  .title {
    margin: 0 0 0.5rem;
    font-size: 1.5rem;
  }

  .price {
    margin: 0;
  }

  .basePrice {
    text-decoration: line-through;
    font-size: 0.9rem;
  }

  .button {
    margin: 0;
    margin-left: 0.5rem;
    padding: 0.7rem 0.5rem;
    border: none;
    border-radius: 0.7rem;
    cursor: pointer;
    background: var(--primary);
    color: #fff;
  }
  .button:hover,
  .container:hover .button {
    opacity: 0.8;
  }

  @media (max-width: 400px) {
    .title {
      font-size: 1.2rem;
    }
  }
</style>
