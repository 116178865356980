<script>
  import Heading from "../components/Heading.svelte"

  const siteUrl = "https://mypetwalker.co.uk"
  // window.location.replace(siteUrl)
</script>

<style>
  p {
    text-align: center;
  }
</style>

<Heading>MyPetWalker</Heading>

<p>
  <a href={siteUrl}>Go to home page</a>
</p>
