<script>
  import Heading from "../components/Heading.svelte"

  setTimeout(window.close(), 3000)
</script>

<Heading>Operation succeeded</Heading>
<p>Everything went fine, you can go back to the App now.</p>
<p>If this doesn't close automatically in 3 seconds close this tab manually</p>

<img src="assets/happyDog.svg" alt="" />

<div class="arrow">&#8593;</div>

<style>
  p {
    margin: 1rem auto 2rem;
    text-align: center;
  }

  img {
    display: block;
    width: 50%;
    max-width: 300px;
    margin: 2rem auto 1rem;
  }

  .arrow {
    position: fixed;
    top: 1rem;
    left: 1rem;
    font-size: 4rem;
    animation: bounce 1s infinite;
  }

  /* Keyframes for the bounce animation */
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-7px);
    }
  }
</style>
