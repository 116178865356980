<script>
  import Heading from "../components/Heading.svelte"

  export let status
  export let error = {}

  const dev = process.env.NODE_ENV === "development"
</script>

<style>
  p {
    margin: 0 auto;
    text-align: center;
  }
</style>

<Heading>{status || '404'}</Heading>

<p>{error.message || 'Not Found'}</p>

{#if dev && error.stack}
  <pre>{error.stack}</pre>
{/if}
