const devPlans = {
  monthly: [
    { id: "price_1OVG4KAovBBsdBLoTW7n4b4o", price: 2000, staff: "1 user" },
    { id: "price_1NXRPJAovBBsdBLoYXzTaJ4I", price: 3500, staff: "2 users" },
    { id: "price_1NXRRBAovBBsdBLoWey1SiBy", price: 4500, staff: "3 users" },
    { id: "price_1NXUaqAovBBsdBLoYblHFd9p", price: 6500, staff: "4-5 users" },
    { id: "price_1NXUbAAovBBsdBLo4KRUDkmY", price: 8500, staff: "6-7 users" },
    { id: "price_1NXUbTAovBBsdBLojHpyT8nY", price: 10500, staff: "8-9 users" },
    { id: "price_1NXUdIAovBBsdBLoXWRZ0SSn", price: 12500, staff: "10-11 users" },
    { id: "price_1NXUdWAovBBsdBLoLHC44QiE", price: 16500, staff: "12+ users" },
  ],
}

const prodPlans = {
  monthly: [
    { id: "price_1OVG7VAovBBsdBLomNWCy5T0", price: 2000, staff: "1 user" },
    { id: "price_1NXiUSAovBBsdBLoifm9MWxI", price: 3500, staff: "2 users" },
    { id: "price_1NXiULAovBBsdBLonpdZ2gTE", price: 4500, staff: "3 users" },
    { id: "price_1NXia1AovBBsdBLoZQtjQI0Y", price: 6500, staff: "4-5 users" },
    { id: "price_1NXiZpAovBBsdBLoiv3AIjcF", price: 8500, staff: "6-7 users" },
    { id: "price_1NXiZeAovBBsdBLoBCBkC3nW", price: 10500, staff: "8-9 users" },
    { id: "price_1NXiZRAovBBsdBLoGmW8HUwk", price: 12500, staff: "10-11 users" },
    { id: "price_1NXiYtAovBBsdBLoM9CJi9KH", price: 16500, staff: "12+ users" },
  ],
}

const businessTypes = [
  { name: "daycare", title: "Daycare", plan: "ESSENTIAL" },
  { name: "dogWalking", title: "Dog Walking", plan: "ESSENTIAL" },
  { name: "grooming", title: "Grooming", plan: "ESSENTIAL" },
  { name: "hotel", title: "Pet Hotel", plan: "ADVANCED" },
  { name: "kennel", title: "Kennel", plan: "ADVANCED" },
]

const plans = process.env.NODE_ENV === "development" ? devPlans : prodPlans

export { businessTypes, plans }
